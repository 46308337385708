<template>
  <div :class="$style.component">
<!--    <search-component></search-component>-->
    <tabs-component></tabs-component>
    <table-component class="q-pa-md"></table-component>
  </div>
</template>
<script>
  import { defineComponent, inject } from 'vue';
  import { startAutoRefreshing } from '@/common/chunks/auto-refresh';
  // import SearchComponent from '@/components/dashboard/components/search.component.vue';
  import TabsComponent from '@/components/dashboard/components/tabs.component.vue';
  import TableComponent from '@/components/dashboard/recent-activities/components/table.component.vue';
  import Store from '@/store';
  import DashboardStore from '@/components/dashboard/dashboard.store';
  import RecentActivitiesStore from '@/components/dashboard/recent-activities/recent-activities.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        await Store.dispatch(`${DashboardStore.name}/${RecentActivitiesStore.name}/getRecentActivities`);
        next();
      } catch (error) {
        next(false);
        throw error;
      }
    },
    setup() {
      startAutoRefreshing({
        dispatchName: `${DashboardStore.name}/${RecentActivitiesStore.name}/getRecentActivities`,
        searchState: null,
        autoRefresh: inject('$autoRefresh'),
      });
    },
    components: {
      // SearchComponent,
      TabsComponent,
      TableComponent,
    },
  });
</script>
<style lang="scss" module>
  .component {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 0 100%;
    overflow: auto;
  }
</style>
