<template>
  <em-table
    :rows="activities"
    :columns="Columns"
    row-key="date"
  >
    <template #avatar="{ item }">
      <q-avatar
        :color="ActivityColor[item.type]"
        class="q-mx-xs"
        text-color="white"
        size="md"
      >
        <q-icon
          :name="ActivityIcon[item.type]"
          size="sm"
        >
          <q-tooltip>
            <span>{{ $t(`MBO.${item.type}`) }}</span>
          </q-tooltip>
        </q-icon>
      </q-avatar>
    </template>
    <template #actions="{ item }">
      <q-btn
        v-if="hasFeature(Feature.eventsWrite)"
        dense
        flat
        round
        icon="open_in_new"
        @click="onItemClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.LINK') }}</span>
        </q-tooltip>
      </q-btn>
    </template>
  </em-table>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';
  import { ActivityColor, ActivityIcon, Columns } from '@/components/dashboard/recent-activities/recent-activities.constant';
  import DashboardStore from '@/components/dashboard/dashboard.store';
  import RecentActivitiesStore from '@/components/dashboard/recent-activities/recent-activities.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const router = useRouter();
      const activities = computed(() => state[DashboardStore.name][RecentActivitiesStore.name].activities);
      return {
        hasFeature,
        Feature,
        activities,
        Columns,
        ActivityColor,
        ActivityIcon,
        onItemClick({ id }) {
          router.push({ name: 'events.event', params: { id } });
        },
      };
    },
  });
</script>
