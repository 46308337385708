<template>
  <div>
    <q-separator></q-separator>
    <q-tabs
      v-model="tab"
      align="left"
    >
      <q-tab
        v-for="(item, index) in tabs"
        :key="index"
        :name="item.name"
        :label="item.label"
      ></q-tab>
    </q-tabs>
    <q-separator></q-separator>
  </div>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import routes from '@/components/dashboard';

  export default defineComponent({
    setup() {
      const router = useRouter();
      const route = useRoute();
      return {
        tab: computed({
          get() {
            return route.name;
          },
          set(payload) {
            router.push({ name: payload });
          },
        }),
        tabs: routes.children.filter((item) => !!item.label),
      };
    },
  });
</script>
